

import { httpUtil } from "@/utils/HttpUtil";
import md5 from "js-md5";


const getToken = () => {
   return httpUtil.get('/user/')
}
const login = (username, password) => {
    password = md5(password)
    return httpUtil.post('/user/login', { "userName": username, "userPassword": password })
}
const rule =()=>{
    return httpUtil.post('/user/rule')
}
const register = (username, password, language = 'en', id = '') => {
    return httpUtil.post('/user/register',
        { 'companyName': username, "password": password, "language": language, "id": id })
}
const logout = () => {
    return httpUtil.post('/user/logout',{})
}
const updatePassword = (username, password) => {
    return httpUtil.post('/user/updatePassword', { 'userName': username, 'password': password })
}
const getBalance=()=>{
    return httpUtil.post('/user/balance',{})
}
const getAllUser=()=>{
    return httpUtil.post('/system/user/getAll',{})
}
const saveUser=(user)=>{
    return httpUtil.post('/system/user/saveUser',user)
}
const deleteUser=(user)=>{
    return httpUtil.post('/system/user/deleteUser',user)
}

export { getToken, login, register, logout, updatePassword,rule,getBalance,getAllUser,saveUser,deleteUser}