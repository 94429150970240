import { httpUtil } from "@/utils/HttpUtil"

const send=(data)=>{
    return httpUtil.post('/sms/send',data)
}
const query=(data)=>{
    return httpUtil.post('/sendlog/query',data)
}
const deleteSendLog=(data)=>{
    return httpUtil.post('/system/sendlog/deleteSendLog',data)
}

export {send,query,deleteSendLog}