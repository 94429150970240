import { getBalance, saveBalance,deleteBalance } from "@/api/BalanceApi";

import { makeAutoObservable } from "mobx";

export default class BalanceStore{
    constructor(){
        makeAutoObservable(this)
    }

    getBalanceInfo=(balance)=>{
        return getBalance(balance)
    }
    saveBalanceInfo=(balance)=>{
        return saveBalance(balance)
    }
    
    deleteBalanceInfo=(balance)=>{
        return deleteBalance(balance)
    }

}