import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/index.scss';
import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <ConfigProvider theme={{
        token: { colorPrimary: '#1677ff', },
        //algorithm: theme.darkAlgorithm,
      }}>
     
        <div className="App">
          <App />
        </div>


    </ConfigProvider>

 
);
