import { observer } from 'mobx-react-lite';
import './index.scss'
import { Table, message } from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SearchOutlined } from '@ant-design/icons';
import {
    Button,
    DatePicker,
    Form,
    Input,
} from 'antd';

import { useStore } from '@/store';

function ReceiptView() {

    dayjs.extend(customParseFormat);
    const [data, setData] = useState([])
    const { sendStore } = useStore()
    


    const columns = [
        {
            title: '编码',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '接收号码',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '短信内容',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: '发送时间',
            dataIndex: 'dateTime',
            key: 'dateTime',
        }, {
            title: '状态码',
            key: 'status',
            dataIndex: 'status',
        },
        {
            title: '备注',
            dataIndex: 'statusMsg',
            key: 'statusMsg',
        },
        {
            title: '回执',
            dataIndex: 'receiveStatusCode',
            key: 'receiveStatusCode',
        },
    ];
    // 改变页码的回调 page代表页码数 pageSize代表每页条数
    const handlePageChange = (currentPage) => {
        onClickQuerySendLog(currentPage)
    };
    const onShowSizeChange = (currentPage, pageSize) => {
        sendStore.setPageAndPageSize(currentPage,pageSize)
    }


    const [totalSize, setTotalSize] = useState(0)


    const paginationProps = {
        showSizeChanger: true,
        onShowSizeChange,
        current: sendStore.sendLogQueryForm.page, //当前页码
        pageSize: sendStore.sendLogQueryForm.pageSize,  // 每页数据条数
        // showTotal: () => (
        //     <span>总共{total}项</span>
        // ),
        total: totalSize, // 总条数
        onChange: page => handlePageChange(page), //改变页码的函数
        hideOnSinglePage: true,
    }
    const onClickQuerySendLog = (currentPage) => {
        sendStore.setPage(currentPage)
        sendStore.querySendLog().then(result => {
            if (result.success) {
                setData(result.data)
                setTotalSize(result.totalSize)
            }
        }).catch(error => {
            message.error(error)
        })
    }



    const [formLayout] = useState('inline');

    const formItemLayout =
        formLayout === 'inline'
            ? {
                labelCol: {
                    span: 10,
                },
                wrapperCol: {
                    span: 30,
                },
            }
            : null;
    const buttonItemLayout =
        formLayout === 'inline'
            ? {
                wrapperCol: {
                    span: 1,
                    offset: 3,
                },
            }
            : null;

    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const onChangeDate = (value, dateString) => {
        const form = sendStore.sendLogQueryForm
        form.dateTime = dateString
        sendStore.setSendLogQueryForm(form)
    }
    const onChangeQueryMobile = (e) => {
        const form = sendStore.sendLogQueryForm
        form.mobile = e.target.value
        sendStore.setSendLogQueryForm(form)
    }
    const onChangeQueryStatus = (e) => {
        const form = sendStore.sendLogQueryForm
        form.status = e.target.value
        sendStore.setSendLogQueryForm(form)
    }

    return (
        <div>
            <Form
                {...formItemLayout}
                layout={formLayout}
                initialValues={{ layout: formLayout, }}
                style={{ maxWidth: 1000, }}>
                <Form.Item label="接收手机号">
                    <Input className='inputMobile' onChange={onChangeQueryMobile} defaultValue={sendStore.sendLogQueryForm.mobile} />
                </Form.Item>
                <Form.Item label="发送日期">
                    <DatePicker onChange={onChangeDate} defaultValue={dayjs(sendStore.sendLogQueryForm.dateTime, dateFormat)} format={dateFormat}
                        renderExtraFooter={() => 'extra footer'} showTime />
                </Form.Item>
                <Form.Item label="状态码">
                    <Input className='inputStatus' onChange={onChangeQueryStatus} defaultValue={sendStore.sendLogQueryForm.status} />
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button onClick={() => onClickQuerySendLog(1)} type="primary" icon={<SearchOutlined />}>查询</Button>
                </Form.Item>
            </Form>
            <Table columns={columns} dataSource={data} pagination={paginationProps} />
        </div>

    )
}

export default observer(ReceiptView)