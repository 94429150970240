
import CommonParamStore from './commonParam.Store';
import SystemSetupStore from './systemSetup.Store';
import SendStore from './send.Store';
import UploadStore from './upload.Store';
import userStore from './user.Store'
import React from 'react'
import CompanyStore from './companyStore';
import SupplierStore from './supplierStore';
import ChannelStore from './channelStore';
import RecordStore from './recordStore';
import PriceStore from './priceStore';
import BalanceStore from './balanceStore';
class RootStore{
    constructor() {
        this.userStore=new userStore();
        this.uploadStore = new UploadStore();
        this.commonParamStore =new CommonParamStore();
        this.sendStore = new SendStore()
        this.systemSetupStore=new SystemSetupStore()
        this.companyStore=new CompanyStore()
        this.supplierStore=new SupplierStore()
        this.channelStore=new ChannelStore()
        this.recordStore=new RecordStore()
        this.priceStore =new PriceStore()
        this.balanceStore=new BalanceStore()
    }
}
const rootStore=new RootStore()
const context=React.createContext(rootStore)
const useStore=()=>React.useContext(context)
export {useStore}