
import './App.scss';
import { HashRouter, Route, Routes } from 'react-router-dom';
import LayerView from './pages/LayerView';
import AuthComponents from './components/AuthComponents';
import SendView from './pages/LayerView/SendView';
import ReceiptView from './pages/LayerView/ReceiptView';
import ProfileView from './pages/LayerView/ProfileView';
import ErrorView from './pages/ErrorView';
import LoginView from './pages/LoginView';
import RegisterView from './pages/RegisterView';


function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<LayerView />}>
          <Route index element={<AuthComponents><SendView /></AuthComponents>} />
          <Route path='/receipt' element={<AuthComponents><ReceiptView /></AuthComponents>} />
          <Route path='/system' element={<AuthComponents><ProfileView /></AuthComponents>} />
        </Route>
        <Route path='/register' element={<RegisterView />} />
        <Route path='/login' element={<LoginView />} />
        <Route path='*' element={<ErrorView />} />
      </Routes>
    </HashRouter>
  )
}

export default App;
