import { makeAutoObservable } from 'mobx'
import { getMobilePrefix } from '@/api/CommonApi'
import { getChannel } from '@/api/ChannelApi'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'



export default class CommonParamStore {


    channel = []
    mobilePrefix = []
    selectedChannalValue = null
    selectedChannelLabel = null
    selectedMobilePrefixValue = null
    selectedMobilePrefixLabel = null
    messageContent = null
    mobiles = null
    sendSetup = null
  

    constructor() {
        makeAutoObservable(this)
    }
    loadSendSetup() {
        const sendSetupStr = getLocalStorage('sendSetup')
        if (sendSetupStr) {
            this.sendSetup = JSON.parse(sendSetupStr)
        } else {
            this.sendSetup = {
                selectedChannalValue: null,
                selectedChannelLabel: null,
                selectedMobilePrefixValue: null,
                selectedMobilePrefixLabel: null,
                messageContent: null,
                mobiles: null,
            }
        }
    }
    setMobiles(mobiles) {
        this.sendSetup.mobiles = mobiles
        //this.mobiles = mobiles
        setLocalStorage('sendSetup', JSON.stringify(this.sendSetup))
    }
    setMessageContent(message) {
        this.sendSetup.messageContent = message
        //this.messageContent = message
        setLocalStorage('sendSetup', JSON.stringify(this.sendSetup))
    }
    setSelectedChannal(value) {
        this.sendSetup.selectedChannalValue = value
        const select = this.channel?.filter(a => {
            return a.value === value
        })[0]
        this.sendSetup.selectedChannelLabel = select.label
        setLocalStorage('sendSetup', JSON.stringify(this.sendSetup))
    }
    setSelectedMobilePrefix(value) {
        this.sendSetup.selectedMobilePrefixValue = value
        const select = this.mobilePrefix?.filter(a => {
            return a.value === value
        })[0]
        this.sendSetup.selectedMobilePrefixLabel = select.label
        setLocalStorage('sendSetup', JSON.stringify(this.sendSetup))
    }

    getChannel() {
        getChannel().then(result => {
            if (result.success) {
                this.channel = result.data
                this.sendSetup.selectedChannelLabel = this.channel?.filter(a => {
                    return a.value === this.sendSetup.selectedChannalValue
                })[0]?.label
            }
        })
    }


    getMobilePrefix() {
        getMobilePrefix().then(result => {
            if (result.success) {
                this.mobilePrefix = result.data
                const mobilePrefixList = this.mobilePrefix?.filter(a => {
                    return a.value === this.sendSetup.selectedMobilePrefixValue
                })
                this.sendSetup.selectedMobilePrefixLabel = mobilePrefixList[0]?.label
            }
        })
    }







}