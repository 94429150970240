import { getAllPrice,savePrice,deletePrice } from "@/api/PriceApi";

const { makeAutoObservable } = require("mobx");

export default class PriceStore{
    constructor(){
        makeAutoObservable(this)
    }
    getAllPriceInfo=(price)=>{
        return getAllPrice(price)
    }
    deletePriceInfo=(price)=>{
        return deletePrice(price)
    }

    savePriceInfo=(price)=>{
        return savePrice(price)
    }



}