import { makeAutoObservable } from 'mobx'
import { send, query, deleteSendLog } from '@/api/SendApi'
import { dateTimeToTimeString } from '@/utils/DateUtil'


export default class SendStore {

    sendLogQueryForm = {
        mobile: '',
        message: '',
        dateTime: dateTimeToTimeString(new Date()),
        status: null,
        pageSize:10,//每页显示几条
        page: 1 //显示第几页
    }

    constructor() {
        makeAutoObservable(this)
    }

    send = async (data) => {
        return await send(data)
    }
    deleteSendLogInfo=async (data)=>{
        return await deleteSendLog(data)
    }

    querySendLog = async () => {
        const queryForm = { ...this.sendLogQueryForm }
        if (!this.testMobile(queryForm.mobile)) {
            queryForm.mobile = ''
        }
        if (queryForm.status < 200) {
            queryForm.status = 0
        }
        return await query({ ...queryForm })
    }
    setPage=(currentPage)=>{
        this.sendLogQueryForm.page=currentPage
    }
    setPageAndPageSize=(currentPage,pageSize)=>{
        this.sendLogQueryForm.page=currentPage
        this.sendLogQueryForm.pageSize=pageSize
    }

    testMobile = (mobile) => {
        var res = /^1[3,4,5,6,7,8,9][0-9]{9}$/
        if (res.test(mobile)) {
            return true
        }
        return false
    }

    setSendLogQueryForm = (form) => {
        this.sendLogQueryForm = form
    }

    



}