import { addCompany, getAllCompany,deleteCompany } from "@/api/CompanyApi";
import { makeAutoObservable } from "mobx";

export default class CompanyStore{
    constructor(){
        makeAutoObservable(this)
    }

    getAllCompanyInfo=()=>{
        return getAllCompany()
    }
    addCompanyInfo=(company)=>{
        if(!company||!company.id||company.id===null){
            company.id=null
        }
        return addCompany(company)
    }
    deleteCompanyInfo=(company)=>{
        return deleteCompany(company)
    }

}