import { saveSupplier, deleteSupplier, getAllSupplier } from "@/api/SupplierApi/supplierApi";
import { makeAutoObservable } from "mobx";

export default class SupplierStore {
    constructor() {
        makeAutoObservable(this)
    }


    getAllSupplierInfo = () => {
        return getAllSupplier()
    }
    
    saveSupplierInfo = (supplier) => {
        return saveSupplier(supplier)
    }

    deleteSupplierInfo = (supplier) => {
        return deleteSupplier(supplier)
    }

    
}