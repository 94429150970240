import { getAllChannel } from '@/api/ChannelApi'
import { getAllCompany } from '@/api/CompanyApi'
import { query } from '@/api/SendApi'
import { makeAutoObservable } from 'mobx'
import { dateTimeToTimeString } from '@/utils/DateUtil'
import { getAllUser } from '@/api/UserApi/api'

export default class SystemSetupStore {
    currentCompany = {
        id: null,
        companyName: '',
        apiKey: '',
        parentId: '',
        state: 0,
        host: '',
        pushAdd: '',
        mobile: '',
        email: ''
    }
    constructor() {
        makeAutoObservable(this)
    }
    setCurrentCompany = (companyInfo) => {
        this.currentCompany = companyInfo
    }

    getAllCompanyInfo = async () => {
        return await getAllCompany()
    }
    getAllUserInfo=async()=>{
        return await getAllUser()
    }
    getAllChannelInfo = async () => {
        return await getAllChannel()
    }

    getTop10SendLog = async () => {
       const sendLogQueryForm = {
            mobile: '',
            message: '',
            dateTime: dateTimeToTimeString(new Date()),
            status: null,
            pageSize:10,//每页显示几条
            page: 1 //显示第几页
        }
    
        return await query({...sendLogQueryForm})
    }



}
