import { Outlet, useLocation, useNavigate } from 'react-router'

import './index.scss'
import HeaderView from '@/pages/LayerView/HeaderView'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'




function LayerView() {
    const navigate = useNavigate()
    const { state } = useLocation()
    useEffect(() => {
        if (state === null) {
            navigate('/login')
        }
    }, [])
    return (
        <div className='layer'>
            <div className='header'>
                <HeaderView props></HeaderView>
            </div>
            <div className='outlet'>
                <Outlet />
            </div>

        </div>

    )
}
export default observer(LayerView)