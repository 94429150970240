
import { getFields } from "@/api/RecordApi";
import { makeAutoObservable } from "mobx";

export default class RecordStore {

   
    constructor() {
        makeAutoObservable(this)
    }


    getFieldsInfo = async (fieldsType) => {
        const data={'type':fieldsType}
        return await getFields(data)
    }



}