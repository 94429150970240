
const setLocalStorage=(key,value)=>{
   return  window.localStorage.setItem(key,value)
}
const getLocalStorage=(key)=>{
   return window.localStorage.getItem(key)
}
const deleteLocalStorage=(key)=>{
   return  window.localStorage.removeItem(key)
}

export {
   setLocalStorage,
   getLocalStorage,
   deleteLocalStorage
}