import { httpUtil } from "@/utils/HttpUtil"

export const getAllCompany = () => {
    return httpUtil.post('/system/company/getAll', {})
}
export const addCompany = (company) => {
    return httpUtil.post('/system/company/save', company)
}
export const deleteCompany = (company) => {
    return httpUtil.post('/system/company/delete', company)
}