import axios from "axios"
import { getLocalStorage } from "@/utils/localStorage"
import { BaseUrl } from '@/utils/HttpUtil/config'


const upload = (data) => {
    const { filename, file } = data
    let formData = new FormData()

    formData.append(filename, file)

    const config = { headers: { 'token': getLocalStorage('token'), 'uuid': getLocalStorage('uuid'), 'Content-Type': 'multipart/form-data' } }
    const httpUtil = axios.create({
        baseURL: BaseUrl,
        timeout: 10000,
    })
    return httpUtil.post('/file/upload', formData, config)
}
export { upload }