import { useLocation } from 'react-router'
import './index.scss'
import { MailOutlined, SettingOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Menu, message } from 'antd';
import { useEffect, useState } from 'react';
import SetupView from './SetupView';
import { useStore } from '@/store';





function ProfileView() {

    const { state } = useLocation()
    //console.log('ProfileView', state)
    const { companyStore, userStore, supplierStore, channelStore, recordStore, priceStore, balanceStore,sendStore } = useStore()
    const ModuleType = {
        company: 'company',
        user: 'user',
        price: 'price',
        balance: 'balance',
        supplier: 'supplier',
        channel: 'channel',
        deleteCompany: 'deleteCompany',
        deleteSendLog: 'deleteSendLog',
        deleteBalance: 'deleteBalance'
    }


    // const [AllModuleFields, setAllModuleFields] = useState()
    const [currentModuleFields, setCurrentModuleFields] = useState()
    const [moduleData, setModuleData] = useState()
    const [moduleName, setModuleName] = useState()
    const [rootSubmenuKeys, setRootSubMenuKeys] = useState([])
    const [items, setItems] = useState([])


    useEffect(() => {
        if (state?.editSubCompany && state?.editSubCompany === true) {
            // recordStore.getFieldsInfo().then(result => {
            //     if (result.success) {
            //         setAllModuleFields(result.data)
            //     }
            // })
            setRootSubMenuKeys(['sub1'])

            setItems([
                getItem('客户管理', 'sub1', <MailOutlined />, [
                    getItem('客户信息', ModuleType.company),
                    getItem('用户和组', ModuleType.user),
                    getItem('售价信息', ModuleType.price),
                    getItem('帐户余额', ModuleType.balance),
                ])])

            if (state.readSupplier && state.readSupplier === true && state.readUser && state.readUser === true) {
                const tSub = ['sub2', 'sub3']
                setRootSubMenuKeys((pro) => {
                    return [...pro, ...tSub]
                })
                const subItem = [getItem('通道管理', 'sub2', <AppstoreOutlined />, [
                    getItem('供应商信息', ModuleType.supplier),
                    getItem('通道列表', ModuleType.channel),
                ]),
                getItem('系统管理', 'sub3', <SettingOutlined />, [
                    getItem('清理客户所有记录', ModuleType.deleteCompany),
                    getItem('清理发送记录', ModuleType.deleteSendLog),
                    getItem('清理充值记录', ModuleType.deleteBalance),
                ])
                ]
                setItems((pro) => {
                    return [...pro, ...subItem]
                })
               // console.log('管理员的权限', tSub)
            }

        }

    }, [])

    // const rootSubmenuKeys = []//['sub1', 'sub2', 'sub3'];
    // const items = [
    //     // getItem('客户管理', 'sub1', <MailOutlined />, [
    //     //     getItem('客户信息', ModuleType.company),
    //     //     getItem('用户和组', ModuleType.user),
    //     //     //getItem('售价', 'price'),
    //     // ]),
    //     // getItem('通道管理', 'sub2', <AppstoreOutlined />, [
    //     //     getItem('供应商信息', ModuleType.supplier),
    //     //     getItem('通道列表', ModuleType.channel),
    //     // ]),
    //     // getItem('系统管理', 'sub3', <SettingOutlined />, [
    //     //     getItem('清理客户所有记录', ModuleType.deleteCompany),
    //     //     getItem('清理发送记录', ModuleType.deleteSendLog),
    //     //     getItem('清理充值记录', ModuleType.deleteBalance),
    //     // ]),
    // ]

    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }






    const [openKeys, setOpenKeys] = useState(['sub1']);
    const onOpenChange = (keys) => {

        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }

    }


    const saveByModule = (data) => {
        switch (moduleName) {
            case ModuleType.company:
                companyStore.addCompanyInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        companyStore.getAllCompanyInfo().then(companyResult => {
                            if (companyResult.success) {
                                setModuleData(companyResult.data)
                            } else {
                                message.error(companyResult.msg)
                            }
                        })
                    } else {
                        message.error(result.msg)
                    }
                }).catch(error=>{message.error(error.message)})
                break
            case ModuleType.user:
                userStore.saveUserInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        userStore.getAllUserInfo().then(userResult => {
                            setModuleData(userResult.data)
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.price:
                priceStore.savePriceInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        priceStore.getAllPriceInfo(data).then(priceResult => {
                            setModuleData(priceResult.data)
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.balance:
                balanceStore.saveBalanceInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        balanceStore.getBalanceInfo(data).then(balanceResult => {
                            setModuleData(balanceResult.data)
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.supplier:
                supplierStore.saveSupplierInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        supplierStore.getAllSupplierInfo().then(supplierResult => {
                            setModuleData(supplierResult.data)
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.channel:
                channelStore.saveChannelInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        channelStore.getAllChannelInfo(data).then(channelResult => {
                            setModuleData(channelResult.data)
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.deleteCompany:
                companyStore.deleteCompanyInfo(data).then(result=>{
                    if(result.success){
                        message.info(result.msg)
                    }else{
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.deleteSendLog:
                sendStore.deleteSendLogInfo(data).then(result=>{
                    if(result.success){
                        message.info(result.msg)
                    }else{
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.deleteBalance:
                balanceStore.deleteBalanceInfo(data).then(result=>{
                    if(result.success){
                        message.info(result.msg)
                    }else{
                        message.error(result.msg)
                    }
                })
                break
            default:
                break
        }
    }
    const deleteByModule = (data) => {
        switch (moduleName) {
            case ModuleType.company:
                companyStore.deleteCompanyInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        companyStore.getAllCompanyInfo().then(companyResult => {
                            if (companyResult.success) {
                                setModuleData(companyResult.data)
                            } else {
                                message.error(companyResult.msg)
                            }
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.user:
                userStore.deleteUserInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        userStore.getAllUserInfo().then(userResult => {
                            if (userResult.success) {
                                message.info(userResult.msg)
                                setModuleData(userResult.data)
                            } else {
                                message.error(userResult.msg)
                            }
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.price:
               // console.log('参数：', data)
                priceStore.deletePriceInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        priceStore.getAllPriceInfo(data).then(priceResult => {
                            setModuleData(priceResult.data)
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.balance:
                balanceStore.deleteBalanceInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        balanceStore.getBalanceInfo(data).then(balanceResult => {
                            setModuleData(balanceResult.data)
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.supplier:
                supplierStore.deleteSupplierInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        supplierStore.getAllSupplierInfo().then(supplierResult => {
                            setModuleData(supplierResult.data)
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.channel:
                channelStore.deleteChannelInfo(data).then(result => {
                    if (result.success) {
                        message.info(result.msg)
                        channelStore.getAllChannelInfo(data).then(channelResult => {
                            setModuleData(channelResult.data)
                        })
                    } else {
                        message.error(result.msg)
                    }
                })
                break
            case ModuleType.deleteCompany:
                break
            case ModuleType.deleteSendLog:
                break
            case ModuleType.deleteBalance:
                break
            default:
                break
        }
    }

    const subOnClickSave = (data) => {

        saveByModule(data)
    }

    const [lastCompanyId, setLastCompanyId] = useState(-1)

    const fieldsChange = (e) => {
        switch (moduleName) {
            case ModuleType.price:
                if (e.companyId && e.companyId >= 0 && lastCompanyId !== e.companyId) {
                    setLastCompanyId(e.companyId)
                    priceStore.getAllPriceInfo(e).then(result => {
                        if (result.success) {

                            //console.log('网络返回：', result.data)
                            setModuleData(result.data)
                        } else {
                            setModuleData([])
                        }
                    })

                }
                break
            case ModuleType.balance:
                if (e.companyId && e.companyId >= 0 && lastCompanyId !== e.companyId) {
                    setLastCompanyId(e.companyId)
                    balanceStore.getBalanceInfo(e).then(result => {
                        if (result.success) {
                            //console.log('网络返回：', result.data)
                            setModuleData(result.data)
                        } else {
                            setModuleData([])
                        }
                    })
                }
                break
            case ModuleType.channel:
               // console.log(lastCompanyId,e)
                if (e.supplierId && e.supplierId >= 0 && lastCompanyId !== e.supplierId) {
                    setLastCompanyId(e.supplierId)
                    channelStore.getAllChannelInfo(e).then(result=>{
                        if(result.success){
                            setModuleData(result.data)
                        }else{
                            setModuleData([])
                        }
                    })
                }
                break
                default:
                    break
        }
    }
    const subOnClickDelete = (e) => {
        deleteByModule(e)
    }
    const onClickMenu = (e) => {
        recordStore.getFieldsInfo(e.key).then(result => {
            setCurrentModuleFields(result)
            switchModule(e.key)
        }).catch(error => {
            message.error(error)
        })
    }

    const switchModule = (moduleName) => {
        setModuleName(moduleName)

        switch (moduleName) {

            case ModuleType.company:
                // console.log('字段㶼据：', Object.prototype.toString.call(fields?.company.state.defaultValue) === '[object Array]')

                companyStore.getAllCompanyInfo().then(result => {
                    if (result.success) {
                        setModuleData(result.data)
                    } else {
                        setModuleData([])
                    }
                })
                break;
            case ModuleType.user:
                userStore.getAllUserInfo().then(result => {
                    if (result.success) {
                        setModuleData(result.data)
                    } else {
                        setModuleData([])
                    }
                })
                break;
            case 'price':
                setModuleData(null)
                break;
            case 'balance':
                setModuleData(null)
                break;
            case ModuleType.supplier:
                supplierStore.getAllSupplierInfo().then(result => {
                    if (result.success) {
                        setModuleData(result.data)
                    } else {
                        setModuleData([])
                    }
                })
                break;
            case ModuleType.channel:
                setModuleData(null)
                break;
            case ModuleType.deleteCompany:
                setModuleData(null)
                break;
            case ModuleType.deleteSendLog:
                setModuleData(null)
                break;
            case ModuleType.deleteBalance:
                setModuleData(null)
                break
            default:
                break
        }

    }


    return (
        <div className='profileViewContainer' >
            <Menu className='leftNavi'
                mode="inline"
                onClick={onClickMenu}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                style={{
                    width: 256,
                }}
                items={items}
            />

            <SetupView className='setupView' fields={currentModuleFields} dataSource={moduleData}
                onClickSave={subOnClickSave} onClickDelete={subOnClickDelete} fieldsChange={fieldsChange} />

        </div >
    )
}
export default ProfileView