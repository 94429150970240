import { makeAutoObservable } from 'mobx'
import { login, getToken, rule,logout,getBalance,deleteUser } from '@/api/UserApi'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { UUID } from 'uuidjs'
import { getAllUser, saveUser } from '@/api/UserApi'

export default class UserStore {


    userName = ''
    loginFlag = false
    
    constructor() {
        makeAutoObservable(this)
    }
    getToken = async () => {
        let token = getLocalStorage("token")
        let uuid = getLocalStorage('uuid')
        setLocalStorage('user', '')
        if (!uuid) {
            uuid = UUID.genV1().hexString
            setLocalStorage('uuid', uuid)
        }
        const result = await getToken()
        if (result.success) {
            token = result.data.id
            setLocalStorage('token', token)
           // console.log(token)
           // console.log(uuid)
            return result
        }
    }

    login = async (username, password) => {
        const result = await login(username, password)
        if (result.success) {
            this.userName = username
            this.loginFlag = true
        }
        return result;
    }

    rule = async () => {
        return await rule()
    }
    logout =async()=>{
        return await logout()
    }
    getBalance=async()=>{
        return getBalance()
        // if(result.success){
        //     this.balance = result.data
        // }
        // return result
    }

    getAllUserInfo=()=>{
        return getAllUser()
    }
    saveUserInfo=(user)=>{
        return saveUser(user)
    }

    deleteUserInfo=(user)=>{
        return deleteUser(user)
    }

}