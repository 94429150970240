import { saveChannel, deleteChannel, getAllChannel } from "@/api/ChannelApi";
import { makeAutoObservable } from "mobx";

export default class ChannelStore {
    constructor() {
        makeAutoObservable(this)
    }

    getAllChannelInfo = (channel) => {
        return getAllChannel(channel)
    }

    saveChannelInfo = (channel) => {
        return saveChannel(channel)
    }

    deleteChannelInfo = (channnel) => {
        return deleteChannel(channnel)
    }

}