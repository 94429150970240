import { httpUtil } from "@/utils/HttpUtil"

export const getAllSupplier=()=>{
    return httpUtil.post('/system/supplier/getAll')
}
export const saveSupplier=(supplier)=>{
    return httpUtil.post('/system/supplier/save',supplier)
}
export const deleteSupplier=(supplier)=>{
    return httpUtil.post('/system/supplier/delete',supplier)
}