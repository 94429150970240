import { useEffect, useState, React } from 'react';

import './index.scss'
import { Button, Col, Form, Input, Row, Space, Table, Popconfirm, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { judgeType } from '@/utils/commonUtil';



function SetupView(props) {

    const { fields, dataSource, onClickSave, onClickDelete, fieldsChange } = props

    const [Fields, setFields] = useState()
    const [DataSource, setDateSource] = useState()


    useEffect(() => {
        setFields(fields)
        setDateSource(dataSource)
        // console.log('接收到的参数：', dataSource,fields)
    }, [fields, dataSource])



    const [form] = Form.useForm();
    const { Option } = Select;

    dayjs.extend(customParseFormat);


    const getFields = () => {

        const children = [];
        for (let key in Fields) {

            children.push(
                <Col span={8}>
                    {judgeType(Fields[key]?.defaultValue) === 'array' ? (
                        <Form.Item
                            name={key}
                            key={key}
                            label={Fields[key]?.label}
                            rules={[
                                {
                                    required: Fields[key]?.required,
                                    message: Fields[key]?.placeholder + '未输入',
                                },
                            ]}
                        >
                            <Select placeholder={Fields[key]?.placeholder}>
                                {
                                    Fields[key]?.defaultValue?.map(values => {
                                        return <Option value={values?.value}>{values?.label}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    ) : judgeType(Fields[key]?.defaultValue) === 'date' ? (
                        <Form.Item
                            name={key}
                            key={key}
                            label={Fields[key]?.label}
                            rules={[
                                {
                                    required: Fields[key]?.required,
                                    message: Fields[key]?.placeholder + '未输入',
                                },
                            ]} >
                            <DatePicker />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name={key}
                            key={key}
                            label={Fields[key]?.label}
                            rules={[
                                {
                                    required: Fields[key]?.required,
                                    message: Fields[key]?.placeholder + '未输入',
                                },
                            ]}>
                            <Input placeholder={Fields[key]?.placeholder} />
                        </Form.Item>
                    )}
                </Col>,
            );
        }
        return children;
    }
    //表单输入完成的事件
    const onFinish = (values) => {
        onClickSave(values)
    };





    const columns = []
    for (let key in Fields) {
        if (Fields[key]?.visible) {
            columns.push({
                title: Fields[key]?.label,
                dataIndex: key,
                sorter: {
                    compare: (a, b) => a.chinese - b.chinese,
                    multiple: 3,
                },
            })
        }
    }
    if (columns?.length > 0) {
        columns.push({
            title: '选择',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {/* <a onClick={() => handleEdit(record)}>修改{record.name}</a> */}
                    <Popconfirm title="删除后无法恢复?" onConfirm={() => handleDelete(record)}>
                        <a style={{ color: 'red' }} >删除记录</a>
                    </Popconfirm>

                </Space>
            ),
        },)
    }

    ///将点击行的数据，加载到输入框内
    const handleEdit = (record) => {
        for (let key in record) {
            form.setFieldValue(key, record[key])
        }
    }

    const handleDelete = (record) => {
        onClickDelete(record)
    };



    return (
        <div className='setupViewContainer'>
            <Form form={form} onFinish={onFinish}
                onValuesChange={(_, allFields) => { fieldsChange(allFields); }}>
                <Row gutter={24}>{getFields()}</Row>
                <div style={{ textAlign: 'right', }}>
                    <Space size="small">
                        <Button key={'1'}
                            onClick={() => {
                                form.resetFields();
                            }}
                            type="primary" htmlType="submit">
                            重置
                        </Button>
                        <Button key={'2'} type="primary" htmlType="submit">
                            保存记录
                        </Button>
                    </Space>
                </div>
            </Form>

            <Table className='setupTable' columns={columns} dataSource={DataSource} size="small"
                onRow={(record) => {
                    return {
                        onClick: (event) => { 
                            handleEdit(record)
                        }, // 点击行
                    };
                }}
            />

        </div>
    )





}
export default SetupView