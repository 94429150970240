import { httpUtil } from "@/utils/HttpUtil"

export const getAllPrice=(price)=>{
    return httpUtil.post('/system/price/getAll',price)
}
export const savePrice=(price)=>{
    return httpUtil.post('/system/price/save',price)
}
export const deletePrice=(price)=>{
    return httpUtil.post('/system/price/delete',price)
}
