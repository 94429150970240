//判断类型，以小写输出
export const judgeType = (target) => {
    if (target) {
        let type = typeof target
        if (type === "object") {
            type = (Object.prototype.toLocaleString.call(target)).toLocaleLowerCase().split(',')
            if(type?.length&&type.length>0){
                return 'array'
            }else{
                type = (Object.prototype.toLocaleString.call(target)).toLocaleLowerCase().slice(8,-1)
                return type
            }
        }else if (type==='number'&&target>168283216900){
            return 'date'
        }
        return type
    }
    return 'undefined'
}