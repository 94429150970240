import './index.scss'
import { Card, Input, Select, Upload, Button, message, Modal } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react';
import { checkFileTail } from '@/utils/fileUtil';
import { useStore } from '@/store';
import { observer } from 'mobx-react-lite';


function SendView() {
    const { TextArea } = Input;
    const { Dragger } = Upload
    const { uploadStore, commonParamStore, sendStore } = useStore()


    useEffect(() => {
        commonParamStore.loadSendSetup()
        commonParamStore.getChannel()
        commonParamStore.getMobilePrefix()

    }, [])


    const onChangeMobilePrefix = (value) => {
        commonParamStore.setSelectedMobilePrefix(value)
    };
    const onChangeChannel = (value) => {
       // console.log(value)
        commonParamStore.setSelectedChannal(value)
    };
   
    const onChangeMessageContent = (e) => {

        commonParamStore.setMessageContent(e.target.value)
    }
    const onChangeMobiles = (e) => {
        let mobiles = e.target.value
        commonParamStore.setMobiles(mobiles)
    }
    const onPressEnterInMobileInput = (e) => {
        let mobiles = e.target.value
        formatMobile(mobiles)
    }
    const formatMobile = (mobiles) => {
        if (mobiles) {
            mobiles = mobiles.replaceAll(' ', ',').replaceAll(',,', ',').replaceAll(/\r\n/g, ',')
            const mobileList = mobiles.split(',')
            if (mobileList?.length > 0) {
                let resultList = []
                for (let i = 0; i < mobileList?.length; ++i) {
                    const mobile = mobileList[i]
                    resultList.push(mobile)
                    // var res = /^1[3,4,5,6,7,8,9][0-9]{9}$/
                    // const mobile = mobileList[i]
                    // if (res.test(mobile)) {
                    //     resultList.push(mobile)
                    // }
                }
                const set = new Set(resultList)
                mobiles = Array.from(set).join(',') + ','
            } else {
                mobiles = ''
            }
        }
        if (mobiles?.length < 11) {
            mobiles = null
        }
        commonParamStore.setMobiles(mobiles)
        //console.log(mobiles)
    }

    const [setUploading] = useState(false)

    const [fileList, setFileList] = useState([])


    const customRequest = (props) => {
        let { file, onSuccess, onError, onProgress } = props
        const data = {
            filename: file.name,
            file: file
        }
        onProgress({ percent: 50 }, file)
        uploadStore.fileUpload(data).then((result) => {
           // console.log('uploadStore', result.data)
            if (result.data.success) {
                onProgress({ percent: 100 }, file)
             //   console.log('返回值：' + result.data.data)
                onSuccess(result.data.data, file)
            } else {
                onError(result.data.msg)
            }
        }).catch((error) => {
            onError(error.message, file)
        }).finally(() => {
            setUploading(false)
        })
    }




    ///let customFileList

    const props = {
        name: 'file',
        multiple: true,

        fileList: fileList,
        beforeUpload: (file) => {
            const isText = checkFileTail(file.name, 'txt')
            const isXLSX = checkFileTail(file.name, 'xlsx')
            const isXLS = checkFileTail(file.name, 'xls')
            const isCSV = checkFileTail(file.name, 'csv')
            if (isText || isXLSX || isXLS || isCSV) {
                if (fileList?.length <= 0) {
                    return isText || isXLSX || isXLS || isCSV || Upload.LIST_IGNORE;
                } else {
                    if (fileList.filter(a => {
                        return a.name === file.name
                    })?.length <= 0) {
                        return isText || isXLSX || isXLS || isCSV;
                    } else {
                        message.error(`${file.name} 己存在列表中`);
                    }
                }
            } else {
                message.error(`${file.name} 文件格式必须是xls、xlsx、csv、txt中任意一种`);
            }
            return Upload.LIST_IGNORE
        },
        onChange(info) {
            const { status } = info.file;

            setFileList((pre) => {

                return info.fileList
            })

            if (status !== 'uploading') {
               // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} 上传成功`);
            } else if (status === 'error') {
                message.error(`${info.file.name} 上传失败`);
            }


        },
        onProgress(progress, file) {
            file.percent = progress.percent
            replace(file)
        }
        ,
        onError(msg, file) {
            //console.log('onError', msg)
            message.error(msg)
            file.status = 'error'
            replace(file)
        },
        onSuccess(res, file) {
            file.status = 'done'
            file.percent = 100
            message.success(res)
            replace(file)
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        customRequest,
    };
    const replace = (file) => {
        setFileList((pre) => {

            const newFileList = pre?.slice() ?? [file]
            if (newFileList && newFileList?.length > 0) {
                for (let i = 0; i < newFileList.length; ++i) {
                    if (newFileList[i].uid === file.uid) {
                        newFileList[i] = file
                        break
                    }
                }
            }
            return newFileList
        })
    }
    const [requestParam, setRequestParam] = useState({})

    const onClickFileSend = () => {
        setRequestParam({})
        formatMobile(commonParamStore.sendSetup.mobiles)
        buildSendParam(true)
    }
    const onClickSend = () => {
        setRequestParam({})
        formatMobile(commonParamStore.sendSetup.mobiles)
        buildSendParam()
    }
    const buildSendParam = (sendFileFlag) => {
        if (!testSendParam()) {
            return
        }
        if (sendFileFlag && fileList.length <= 0) {
            message.error('未上传号码文件')
           // console.log('没有找到文件')
            setRequestParam({})
            return
        }
        showModal()
        let param = {
            channel: commonParamStore.sendSetup.selectedChannalValue,
            mobilePrefix: commonParamStore.sendSetup.selectedMobilePrefixValue,
            destinationNum: commonParamStore.sendSetup.mobiles,
            message: commonParamStore.sendSetup.messageContent,
        }
        if (sendFileFlag) {
            //console.log('点击发送文件')
            let fileNameList = []
            for (let i = 0; i < fileList.length; i++) {
                fileNameList.push(fileList[i].name)
            }
            const fileNames = fileNameList.join(',')
            param = { ...param, fileList: fileNames }
            setRequestParam(param)
           // console.log('点击发送文件参数', requestParam)
        } else {
            setRequestParam(param)
           // console.log('点击发送参数', requestParam)
        }


    }

    const testSendParam = () => {
        if (commonParamStore.sendSetup.mobiles?.length < 11) {
            message.error('接收的号码不能为空')
            return false
        }
        if (commonParamStore.sendSetup.messageContent?.length <= 0) {
            message.error('短信内容不能为空')
            return false
        }
        if (commonParamStore.sendSetup.selectedMobilePrefixValue?.length <= 1 || commonParamStore.sendSetup.selectedMobilePrefixLabel?.length <= 1) {
            message.error('号码前缀未指定')
            return false
        }
        if (commonParamStore.sendSetup.selectedChannalValue?.length <= 2 || commonParamStore.sendSetup.selectedChannelLabel?.length <= 2) {
            message.error('未设置发送通道')
            return false
        }
        return true
    }

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState(commonParamStore.sendSetup ?? commonParamStore.sendSetup?.selectedChannelLabel ?? '');
    const showModal = (fileMode) => {
        if (fileMode) {
            setModalText((<div>
                <div>发送通道：{commonParamStore.sendSetup.selectedChannelLabel}</div>
                <div>号码前缀：{commonParamStore.sendSetup.selectedMobilePrefixLabel}</div>
                <div>文件个数：{fileList?.length}</div>
            </div>));
        } else {
            setModalText((<div>
                <div>发送通道：{commonParamStore.sendSetup.selectedChannelLabel}</div>
                <div>号码前缀：{commonParamStore.sendSetup.selectedMobilePrefixLabel}</div>
            </div>));
        }
        setOpen(true);
    };
    const handleOk = async () => {
        setConfirmLoading(true);
        try {
           // console.log('请求参数：', requestParam)
            const result = await sendStore.send(requestParam)
          //  console.log(result)
            if (result.success) {
                setOpen(false);
                setConfirmLoading(false);
                message.info('提交成功')
            } else {
                message.error(result.msg)
            }
        } catch (error) {
            message.error('提交失败')
        } finally {
            setOpen(false);
            setConfirmLoading(false);
        }
    };
    const handleCancel = () => {
       // console.log('Clicked cancel button');
        setOpen(false);
    };


    return (
        <div className='sendArea'>
            <Card >
                <TextArea
                    showCount
                    allowClear
                    autoSize={{ minRows: 5, maxRows: 200, }}
                    defaultValue={commonParamStore.sendSetup?.messageContent}
                    value={commonParamStore.sendSetup?.messageContent}
                    onChange={onChangeMessageContent}
                    placeholder="待发送的短信内容" maxLength={200} />
                <TextArea
                    showCount
                    allowClear
                    autoSize={{ minRows: 10, maxRows: 10000, }}
                    defaultValue={commonParamStore.sendSetup?.mobiles}
                    value={commonParamStore.sendSetup?.mobiles}
                    onChange={onChangeMobiles}
                    onPressEnter={onPressEnterInMobileInput}
                    placeholder="待发送的号码,号码以空格可逗号分隔" maxLength={1000} />
                <div className='actionArea'>
                    <Select
                        defaultValue={commonParamStore.sendSetup?.selectedMobilePrefixLabel}
                        value={commonParamStore.sendSetup?.selectedMobilePrefixLabel}
                        showSearch
                        placeholder="接收短信的国家码默认-86"
                        optionFilterProp="children"
                        onChange={onChangeMobilePrefix}
                    
                        size={'large'}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={commonParamStore.mobilePrefix}
                    />
                    <Select
                        defaultValue={commonParamStore.sendSetup?.selectedChannelLabel}
                        value={commonParamStore.sendSetup?.selectedChannelLabel}
                        showSearch
                        placeholder="发送短信通道"
                        optionFilterProp="children"
                        onChange={onChangeChannel}
                       
                        size={'large'}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={commonParamStore.channel}
                    />

                    <Button onClick={onClickFileSend} type="primary" size={'large'} danger>号码和文件一起提交</Button>
                    <Button onClick={onClickSend} type="primary" size={'large'} >只提交号码</Button>
                </div>


                <Dragger className='upload-list-inline' {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">点击或拖动文件到此处进行号码上传</p>
                    <div className="ant-upload-hint">
                        <p>
                            (支持多文件同时上传,一次上传所有文件总大小不得大于5M)/(如果号码比较多,见意使用xlsx格式,文件比较小)/(号码保留10分钟，过期后号码文件将失效))
                        </p>
                    </div>
                </Dragger>

                <Modal
                    title="提交确认"
                    open={open}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}>
                    <>{modalText}</>
                </Modal>


            </Card>
        </div >
    )
}
export default observer(SendView)