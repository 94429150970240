import { httpUtil } from "@/utils/HttpUtil"

export const getBalance=(balance)=>{
    return httpUtil.post('/system/balance/getAll',balance)
}
export const saveBalance=(balance)=>{
    return httpUtil.post('/system/balance/save',balance)
}
export const deleteBalance=(balance)=>{
    return httpUtil.post('/system/balance/delete',balance)
}