
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";

function AuthComponents({ children }) {

    const { state } = useLocation()
    try {
        if (state===null) {
            return <Navigate to='/login' replace={true} />
        }else{
           // console.log('AuthComponents接收到的参数', state)
            if (state.userName) {
                return <>{children}</>
            } else {
                return <Navigate to='/login' replace={true} />
            }
        }
    } catch (error) {
        return <Navigate to='/login' replace={true} />
    }

}
export default observer(AuthComponents)