import { makeAutoObservable } from 'mobx'
import { upload } from '@/api/UploadApi/index'

export default class UploadStore {

    constructor() {
        makeAutoObservable(this)
    }

    fileUpload = async (data) => {
        return await upload(data)
    }
 

}