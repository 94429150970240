import { useEffect, useState } from 'react'
import './index.scss'
import { Dropdown, Space, message } from 'antd'
import { CodeSandboxSquareFilled, QqCircleFilled, DownOutlined, SmileOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router'
import { useStore } from '@/store'
import { observer } from 'mobx-react-lite';

function HeaderView() {

    const navigate = useNavigate()
    const { state } = useLocation()
    const { userStore } = useStore()
    const [userMenuProps, setUserMenuProps] = useState([])
    //console.log('HeaderView传过来的参数：', state)
    const [menuItem, setMenuItem] = useState([{ label: '发送记录', url: '/receipt' }])

    useEffect(() => {
        if (state === null) {
            navigate('/login')
        }
        if(state?.editSubCompany&&state?.editSubCompany===true){
           // console.log('useEffect', state)

            setMenuItem((pre) => {
                const systemMenu = {
                    label: '系统管理',
                    url: '/system'
                }
                if (pre.filter(a => {
                    return a.label === systemMenu.label
                })?.length <= 0) {
                    return [...pre, systemMenu]
                }
            })
        }
       // userStore.getBalance()
    }, [])


    // const items = [
    //     {
    //         key: '2',
    //         label: (
    //             <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
    //                 余额： {userStore.balance}
    //             </a>
    //         ),
    //         icon: <SmileOutlined />,
    //         disabled: true,
    //     },
    //     {
    //         key: '4',
    //         danger: true,
    //         label: '退出',
    //     },
    // ];
    const onClickMenu = (item) => {
       // console.log(item)
        navigate(item.url, { state: { ...state } })
    }
    const onclickUser = (e) => {
       // console.log('onclickUser', e)
        userStore.logout().then(result => {
            message.info('退出登陆！')
        })
        navigate('/login')
    }

    const loadBalance = (flag) => {
        if(flag){
            userStore.getBalance().then(result=>{
                if(result.success){
                    setUserMenuProps([{ key: '1', label: '余额：'+result.data }, { key: '2', label: '退出' }]);
                }else{
                    setUserMenuProps([ { key: '2', label: '退出' }])
                }
            })
        }
        
    }

    return (
        <div className='headerArea'>

            <div className='logo' onClick={() => onClickMenu({ label: '', url: '/' })}>
                <CodeSandboxSquareFilled className='banner' />
                <h4>短信中心</h4>
            </div>
            <div className='menu'>
                {menuItem?.map((item) => (
                    <div key={item.label} onClick={() => onClickMenu(item)} >
                        {item.label}
                    </div>
                )
                )}

            </div>
            <div className='userIconArea'>

                <QqCircleFilled className='userIcon' />
                <Dropdown menu={{ items:userMenuProps, onClick: onclickUser }} onOpenChange={loadBalance}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <h4>{state?.userName}</h4>
                            <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>


            </div>
        </div>
    )
}
export default observer(HeaderView)