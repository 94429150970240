const getPureFileName = (fileName) => {
    return fileName.substring(0, fileName.indexOf('.'))
}
const getFileType = (fileName) => {
    return fileName.substring(fileName.lastIndexOf('.') + 1)
}
const checkFileTail = (filename, tail) => {
    const suffix = getFileType(filename)
    return suffix.toUpperCase() === tail.toUpperCase()
}
export { checkFileTail, getPureFileName, getFileType }