
import './index.scss'
import { Button, Form, Input, Card, message } from 'antd'
import { useNavigate } from 'react-router';
import { useStore } from '@/store';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

function LoginView() {
    const { userStore } = useStore()
    useEffect(() => {
        userStore.getToken().then(result => {
           
            if (!result.success){
                message.error(result.msg)
            }
        }).catch((error) => {
            message.error('未登陆请刷新页面后重试：')
        })
    }, [userStore])
    const navigate = useNavigate()
    const onFinish = (values) => {
        userStore.login(values.username, values.password).then(result => {
           
            if (result.success) {
                message.info('登陆成功');
                
                navigate('/', { state: {...result.data,userName:values.username} })
            } else {
                message.error(result.msg);
            }
        })
    };
   
    return (
        <div className='login'>
            <Card className='loginForm'>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                   
                    autoComplete="off"
                >
                    <Form.Item
                        label="用户名"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

    

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            用户登陆
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}
export default observer(LoginView)