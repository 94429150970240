import { httpUtil } from "@/utils/HttpUtil";



export const getChannel = () => {
    return httpUtil.get('/channel/getChannel')
}

export const getAllChannel = (channel) => {
    return httpUtil.post('/system/channel/getAll', channel)
}
export const saveChannel = (channel) => {
    return httpUtil.post('/system/channel/save', channel)
}
export const deleteChannel = (channel) => {
    return httpUtil.post('/system/channel/delete', channel)
}