import axios from "axios"
import { BaseUrl, TimeOut } from "./config";
import { getLocalStorage } from "../localStorage";
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import { message } from "antd";

const httpUtil = axios.create({
    baseURL: BaseUrl,
    timeout: TimeOut,
})
//请求拦截器
httpUtil.interceptors.request.use(config => {
    const token = getLocalStorage("token")
    const uuid = getLocalStorage('uuid')
    config.headers['token']=token
    config.headers['uuid'] = uuid
    config.headers['Content-Type']='application/json; charset=UTF-8'
    config.withCredentials=true
    nprogress.start()
    return config
}, error => {
    return Promise.reject(error)
})
//响应拦截器
httpUtil.interceptors.response.use((response) => {
    nprogress.done()
    if(response?.data?.code===314){
        message.error(response.data?.msg)
    }
    return response.data
}, (error) => {
    nprogress.done()
    return Promise.reject(error)
})
export { httpUtil }